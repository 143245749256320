<template>
  <li class="sanfang_box">
    <ul class="sanfang_list clearfix">
      <li class="sanfang_item"
          @click="applelogin"
          v-if="appleData.login_way_id && appleData.login_way_id > 0">
        <img src="../../assets/images/mycard/apple.png"
             alt="" />
      </li>
      <li class="sanfang_item"
          v-if="googleData.login_way_id && googleData.login_way_id > 0">
        <!-- <img src="../../assets/images/google_icon.png"
             alt=""> -->
        <template>
          <!--          <g-signin-button-->
          <!--            :params.sync="googleData.googleSignInParams"-->
          <!--            @success="onSignInSuccess"-->
          <!--            @error="onSignInError"-->
          <!--          >-->
          <!--          </g-signin-button>-->
          <google-login-btn :params="googleData.googleSignInParams"
                            @success="googleSuccess"
                            @error="googleError">
          </google-login-btn>
        </template>
      </li>
      <li class="sanfang_item"
          v-if="fbData.login_way_id && fbData.login_way_id > 0">
        <!-- <img src="../../assets/images/fb_icon.png"
             alt=""> -->
        <fb-signin-button :params="fbSignInParams"
                          @success="onFbSignInSuccess"
                          @error="onFbSignInError">
        </fb-signin-button>
      </li>
      <li class="sanfang_item"
          v-if="lineData.login_way_id && lineData.login_way_id > 0">
        <img src="../../assets/images/mycard/line.png"
             alt="" />
        <vue-line-login class="line_btn"
                        :client-id="lineData.channel_id"
                        :client-secret="lineData.channel_secret"
                        :callback-uri="callbackUri"
                        @result="lineLoginResult"
                        :login-way-id="lineData.login_way_id"
                        :add-friend="true"
                        :friend-required="false"
                        :client_game_app_id="client_game_app_id"></vue-line-login>
      </li>
      <li class="sanfang_item"
          v-if="false">
        <steam-login :login_way_id="steamData.login_way_id"
                     @SteamLoginResult="SteamLoginResult"
                     @SteamLoginErr="SteamLoginErr"></steam-login>
      </li>
    </ul>
    <p class="buy_tips">{{ $t("myCard.tip") }}</p>
    <loading v-if="showLoading"></loading>
  </li>
</template>

<script>
import { getSessionStorage, setToken, setLocalStorage } from '@/utils/stage'
import { googleLogin, appleLogin, fbLogin } from '@/api/login'
import { randomString } from '@/utils/crypto'
import VueLineLogin from '@/views/vue-line-login/src/components/LineLoginButton'
import Loading from '@/views/MyCard/components/loading'
import SteamLogin from '@/views/steamLogin/steamLoginButton'
// import GoogleLoginBtn from "@/views/google-btn/google-btn";
export default {
  data() {
    return {
      fbSignInParams: {
        scope: 'email,user_likes',
        return_scopes: true,
      },
      appRandomString: randomString(16),
      configData: '',
      googleData: {
        login_way_id: '',
        googleSignInParams: {
          client_id: '',
        },
      },
      fbData: {
        login_way_id: '',
        app_id: '',
      },
      appleData: {
        login_way_id: '',
        client_id: '',
      },
      lineData: {
        channel_id: '',
        channel_secret: '',
        login_way_id: '',
      },
      twitterData: {
        login_way_id: '',
      },
      steamData: {
        login_way_id: '',
      },
      showLoading: false,
      callbackUri: window.location.origin + window.location.pathname,
    }
  },
  props: {
    client_game_app_id: {
      default: '',
      type: String,
    },
  },
  created() {
    this.init()
    this.showLoading = window.location.search.indexOf('code') > -1
  },
  components: {
    VueLineLogin,
    Loading,
    SteamLogin,
    // GoogleLoginBtn,
  },
  methods: {
    init() {
      getSessionStorage('configInit') ? '' : this.$router.go(0)
      const config = JSON.parse(getSessionStorage('configInit')).login_ways
      //   console.log(6667, config, config.login_ways[1].config.client_id)
      //   const num = config.login_ways[1].config.client_id.replace(
      //     /^\s*|\s*$/g,
      //     ''
      //   )
      for (const i in config) {
        if (config[i].type == 5) {
          this.googleData.login_way_id = config[i].id
          const num = config[i].config.client_id.replace(/^\s*|\s*$/g, '')
          this.googleData.googleSignInParams.client_id = num
        } else if (config[i].type == 4) {
          this.fbData.login_way_id = config[i].id
          this.fbData.app_id = config[i].config.app_id
        } else if (config[i].type == 3) {
          this.appleData.login_way_id = config[i].id
          this.appleData.client_id = config[i].config.client_id
        } else if (config[i].type == 8) {
          this.lineData.login_way_id = config[i].id
          this.lineData.channel_id = config[i].config.channel_id
          this.lineData.channel_secret = config[i].config.channel_secret
        } else if (config[i].type == 9) {
          this.steamData.login_way_id = config[i].id
        }
      }
      this.configData = JSON.parse(getSessionStorage('message'))
      // app初始化
      const appNonce = this.appRandomString
      AppleID.auth.init({
        clientId: this.appleData.client_id,
        scope: 'email',
        // redirectURI: "https://passport-web-pay.17atv.com/accountlogin",
        redirectURI: window.location.origin + window.location.pathname,
        state: 'DE',
        usePopup: true,
        nonce: appNonce,
      })
      //Listen for authorization success
      document.addEventListener('AppleIDSignInOnSuccess', (data) => {
        // appleLogin().then((res) => {})
        console.log(data.detail.authorization.id_token)
        const data1 = {
          nonce: this.appRandomString,
          token: data.detail.authorization.id_token,
          client_game_app_id: this.client_game_app_id,
        }
        const msg = Object.assign(
          data1,
          JSON.parse(JSON.stringify(this.configData))
        )
        console.log(data.detail, msg)
        appleLogin(msg).then((res) => {
          console.log(res)
          if (res.errcode === 0) {
            setLocalStorage(
              'accountStorage',
              JSON.stringify(res.data.user_entity)
            )
            setToken('login', 'success', 0.0007)
            this.$router.push({ path: '/payOnline' })
          }
        })
      })
      //Listen for authorization failures
      document.addEventListener('AppleIDSignInOnFailure', (error) => {
        console.log(error)
      })
      this.fbInit()
    },
    googleSuccess(user) {
      const data = {
        login_token: user.credential,
        login_way_id: this.googleData.login_way_id,
        client_game_app_id: this.client_game_app_id,
      }
      const msg = Object.assign(
        data,
        JSON.parse(JSON.stringify(this.configData))
      )
      googleLogin(msg).then((res) => {
        if (res.errcode === 0) {
          setLocalStorage(
            'accountStorage',
            JSON.stringify(res.data.user_entity)
          )
          setToken('login', 'success', 7)
          // this.$router.push({ path: "/safetySettings" });
          this.$router.push({ path: '/payOnline' })
        }
      })
    },
    googleError(error) {
      console.log('OH NOES', error)
      this.$refs.toast.show(this.langObj[this.lang].message.toastError)
    },
    fbInit() {
      const appId = this.fbData.app_id
      FB.init({
        appId: appId,
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v12.0',
      })
    },
    onSignInError(error) {
      console.log('OH NOES', error)
    },
    onFbSignInSuccess(res) {
      FB.api('/me?fields=id,name,token_for_business', (dude) => {
        console.log(`Good to see you, ${dude}.`)
        console.log(dude, JSON.stringify(dude))
        const data = {
          user_id: dude.id,
          input_token: res.authResponse.accessToken,
          union_id: dude.token_for_business,
          login_way_id: this.fbData.login_way_id,
          client_game_app_id: this.client_game_app_id,
        }
        const msg = Object.assign(
          data,
          JSON.parse(JSON.stringify(this.configData))
        )
        fbLogin(msg).then((res) => {
          setLocalStorage(
            'accountStorage',
            JSON.stringify(res.data.user_entity)
          )
          setToken('login', 'success', 7)
          this.$router.push({ path: '/payOnline' })
        })
      })
      console.log(res) //返回第三方的登录信息 token等
    },
    onFbSignInError(error) {},
    applelogin() {
      AppleID.auth.signIn()
    },
    lineLoginResult(res) {
      if (res !== 'error') {
        setLocalStorage('accountStorage', JSON.stringify(res.data.user_entity))
        setToken('login', 'success', 7)
        this.showLoading = false
        window.location.href = window.location.origin + '/payOnline'
      } else {
        this.$toast(this.$t('myCard.failed'))
        this.showLoading = false
        window.location.href = window.location.origin + '/accountlogin'
      }
    },
    SteamLoginResult(res) {
      setLocalStorage('accountStorage', JSON.stringify(res.data.user_entity))
      setToken('login', 'success', 7)
      this.showLoading = false
      window.location.href = window.location.origin + '/payOnline'
    },
    SteamLoginErr() {
      this.$toast(this.$t('myCard.failed'))
      this.showLoading = false
      window.location.href = window.location.origin + '/accountlogin'
    },
  },
}
</script>

<style scoped lang="scss">
.sanfang_box {
  width: 100%;
  text-align: center;
  padding: 120 * 0.75px 0;
  font-size: 0;
  .buy_tips {
    font-size: 13px;
    color: #999;
    margin-top: 38 * 0.75px;
  }
  .line {
    width: 555 * 0.8px;
    margin: 0 auto;
    height: 1 * 0.85px;
    border-bottom: 1 * 0.85px dashed #a8a8a8;
    margin-top: 55 * 0.85px;
    text-align: center;
    position: relative;
    .text1 {
      width: 200 * 0.85px;
      font-size: 20 * 0.85px;
      padding: 5 * 0.85px 5 * 0.85px;
      display: inline-block;
      position: absolute;
      top: -18 * 0.85px;
      background: #f7f7f7;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }
  .sanfang_list {
    width: 470 * 0.75px;
    margin: 0 auto;
    margin-top: 30 * 0.75px;
    .sanfang_item {
      width: 220 * 0.75px;
      height: 36 * 0.75px;
      display: inline-block;
      margin: 6px 4px;
      border-radius: 36 * 0.75px;
      position: relative;
      cursor: pointer;
      vertical-align: top;
    }
  }
  .fb-signin-button {
    width: 220 * 0.75px;
    height: 36 * 0.75px;
    background: url('../../assets/images/mycard/fb.png') no-repeat;
    background-size: contain;
  }
  .g-signin-button {
    width: 220 * 0.75px;
    height: 36 * 0.75px;
    background: url('../../assets/images/mycard/google.png') no-repeat;
    background-size: contain;
  }
}

.line_btn {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  ::v-deep button {
    cursor: pointer !important;
  }
}
</style>
