<template>
  <div class="register_box">
    <div id="captcha"></div>
    <p class="pravic_tip">注册即代表您同意并遵守
      <a class="link_to"
         href="javascript:;"
         @click="changeFlag">
        《FUNGO使用许可及服务协议》《FUNGO用户个人信息及隐私保护政策》
      </a>
    </p>
    <ul class="ft_register_box">
      <li class="register_item">
        <input type="text"
               v-model="terminal"
               :placeholder="$t('toast.phonePlaceholder')">
      </li>
      <li class="
               register_item
               verifiy_item">
        <input type="text"
               class="verify_input"
               :placeholder="$t('toast.verifycodePlaceholder')"
               v-model="verify_code">
        <button :disabled="showIndex"
                class="fr v_btn verify_btn"
                :class="{ disabled: showIndex }"
                @click="clickVerifyCode">
          {{ btnValue }}
        </button>
      </li>
      <li class="register_item">
        <input class="fl psw_code"
               :type="pswtype"
               :placeholder="$t('toast.verifypswTips')"
               v-model="password">
        <span class="fr v_btn v_btn1"
              @click="pswVisble">
          <img :src="imgUrl"
               alt="" />
        </span>
      </li>
      <li class="register_item">
        <input class="fl psw_code"
               :type="repeatpswtype"
               :placeholder="$t('toast.confirmPswPlaceholder')"
               v-model="repeatpsw">
        <span class="fr v_btn v_btn1"
              @click="repeatpswVisble">
          <img :src="imgUrl1"
               alt="" />
        </span>
      </li>
      <li class="register_submit"
          @click="submitAccount">
        {{$t('toast.comfirmBtn3')}}
      </li>
      <sanfang />
    </ul>

    <!-- <fcm-rehister v-if="tipsFlag"
                  @closeTips="closeTips" /> -->
  </div>
</template>

<script>
import sanfang from '../component/sanfangLogin.vue'
import usercontent from '../Home/components/user.vue'
import initNECaptchaWithFallback from '@/assets/js/yidun-captcha'
import { terminalRegister, sendVerifyCode, key } from '@/api/login'
import { setLocalStorage, getSessionStorage } from '@/utils/stage'
import { encrypt, encodeBase64, HmacSHA256, randomString } from '@/utils/crypto'
import fcmRehister from './components/fcmregister.vue'
const IDreg =
  /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
export default {
  components: {
    fcmRehister,
    usercontent,
    sanfang,
  },
  data() {
    return {
      terminal: '',
      scene_id: '',
      password: '',
      repeatpsw: '',
      verify_code: '',
      identity_no: '',
      real_name: '',
      btnValue: this.$t('toast.verifycodeBtn'),
      showIndex: false,
      pswtype: 'password',
      repeatpswtype: 'password',
      repeatpswVisbleFlag: false,
      pswVisbleFlag: false,
      imgUrl: require('@/assets/images/invisible_icon.png'),
      imgUrl1: require('@/assets/images/invisible_icon.png'),
      token: '',
      NECaptcha: '',
      validate: '',
      tipsFlag: false,
      codeFlag: false,
      ydErr: false,
      configData: '',
      token: '',
    }
  },
  watch: {
    verify_code: function (val) {
      this.verify_code = val.replace(/\D/g, '')
    },
  },
  created() {
    this.init()
  },
  methods: {
    changeFlag() {
      this.$emit('flagChange', true)
    },
    init() {
      this.configData = JSON.parse(getSessionStorage('message'))
      this.getWm()
    },
    NECaptchaInit() {
      const captchaId = this.$store.getters.captcha_id
      var that = this
      var neWidth = 360 + 'px'
      // initNECaptcha为全局函数，可直接调用
      initNECaptchaWithFallback(
        {
          // config对象，参数配置
          captchaId: captchaId, // 从易盾申请的验证码id
          element: '#captcha', // 容器元素/容易元素选择器
          mode: 'bind', // 验证码模式
          width: neWidth, // 验证按钮宽度，推荐260px-400px
          onVerify: function (err, data) {
            // console.log(data)
            // 验证码验证结束回调函数
            // that.validate = data
            if (data) {
              that.sendVerifyCode(data.validate)
              that.NECaptchaInit()
            }
          },
        },
        function onload(instance) {
          // 初始化成功后得到验证实例instance，可以调用实例的方法。
          that.NECaptcha = instance
        },
        function onerror(err) {
          that.ydErr = false
          // 初始化失败后触发该函数，err对象描述当前错误信息
        }
      )
    },
    clickVerifyCode() {
      if (!this.terminal) {
        this.$toast(this.$t('toast.noPhone'))
        return
      }
      if (this.checkPhone(this.terminal)) {
        if (this.ydErr) {
          this.NECaptcha && this.NECaptcha.verify()
        } else {
          this.sendVerifyCode()
          return
        }
      }
    },
    closeTips(data) {
      if (data) {
        this.getSubmit()
      }
      this.tipsFlag = false
    },
    getCode() {
      this.showIndex = true
      let show = true
      let count = ''
      let timer = null
      const TIME_COUNT = 60
      if (!timer) {
        count = TIME_COUNT
        show = false
        timer = setInterval(() => {
          if (count > 0 && count <= TIME_COUNT) {
            count--
          } else {
            show = true
            clearInterval(timer)
            timer = null
          }
          if (count === 0) {
            this.btnValue = this.$t('toast.verifycodeBtn2')
            this.showIndex = false
            return
          } else {
            this.btnValue = count + 's'
          }
        }, 1000)
      }
    },
    sendVerifyCode(valid) {
      let validateData = ''
      if (typeof valid === 'string') {
        validateData = valid
      }
      if (!this.codeFlag) {
        this.codeFlag = true
        const data = {
          terminal: this.terminal,
          scene_id: 3,
          validate: validateData,
        }
        const msg = Object.assign(
          JSON.parse(JSON.stringify(this.configData)),
          data
        )
        sendVerifyCode(msg).then((res) => {
          if (res.errcode === 503) {
            if (this.ydErr) {
              this.NECaptcha && this.NECaptcha.verify()
            } else {
              this.sendVerifyCode()
            }
          } else if (res.errcode === 0) {
            this.getCode()
            this.$toast(res.errmsg)
          }
        })
      }
      setTimeout(() => {
        this.codeFlag = false
      }, 1000)
    },
    // 查看密码
    pswVisble() {
      this.pswVisbleFlag = !this.pswVisbleFlag
      if (this.pswVisbleFlag) {
        this.imgUrl = require('@/assets/images/visible_icon.png')
        this.pswtype = 'text'
      } else {
        this.imgUrl = require('@/assets/images/invisible_icon.png')
        this.pswtype = 'password'
      }
    },
    repeatpswVisble() {
      this.repeatpswVisbleFlag = !this.repeatpswVisbleFlag
      if (this.repeatpswVisbleFlag) {
        this.imgUrl1 = require('@/assets/images/visible_icon.png')
        this.repeatpswtype = 'text'
      } else {
        this.imgUrl1 = require('@/assets/images/invisible_icon.png')
        this.repeatpswtype = 'password'
      }
    },
    checkoutPsw() {
      const reg =
        /^[0-9a-zA-Z`~!@#$%^&*()+=|\[\]{}\':;,.<>?~！@#￥%……&*（）——+|{}【】‘；：”“’。，、]{6,18}$/
      if (!reg.test(this.password)) {
        this.$toast(this.$t('toast.verifypswTips'))
      }
    },
    repeatPsw() {
      if (this.repeatpsw != this.password) {
        this.$toast(this.$t('toast.confirmPswTips'))
      }
    },
    // 注册账号
    submitAccount() {
      if (this.terminal === '') {
        this.$toast(this.$t('toast.noPhone'))
        return
      }
      if (!this.checkPhone(this.terminal)) {
        return
      }
      if (this.verify_code === '') {
        this.$toast(this.$t('toast.verifycodeTips'))
        return
      }
      if (this.password === '') {
        this.$toast(this.$t('toast.nopsw'))
        return
      }
      const reg =
        /^[0-9a-zA-Z`~!@#$%^&*()+=|\[\]{}\':;,.<>?~！@#￥%……&*（）——+|{}【】‘；：”“’。，、]{6,18}$/
      if (!reg.test(this.password)) {
        this.$toast(this.$t('toast.verifypswTips'))
        return
      }

      if (this.repeatpsw != this.password) {
        this.$toast(this.$t('toast.confirmPswTips'))
        return
      }
      // if (this.real_name === '') {
      //   this.$toast(this.$t('toast.noName'))
      //   return
      // }
      // if (this.identity_no === '') {
      //   this.$toast(this.$t('toast.noID'))
      //   return
      // }
      // else if (!IDreg.test(this.identity_no)) {
      //   this.$toast(this.$t('toast.verifyIDTips'))
      //   return
      // } else if (parseInt(this.IdCard(this.identity_no)) < 18) {
      //   this.tipsFlag = true
      // }
      // else {
      this.getSubmit()
      // }
    },
    getWm() {
      const _this = this
      if (window.wm) {
        window.wm &&
          window.wm.getToken(
            this.$store.getters.yd_product_id,
            function (token) {
              _this.token = token
            },
            function (error) {
              // 错误处理
            }
          )
      }
    },
    getSubmit() {
      const msg1 = Object.assign(
        JSON.parse(JSON.stringify(this.configData)),
        {}
      )
      const iv = randomString(16)
      const iv_data = encodeBase64(iv)
      const _this = this
      //   window.wm &&
      //     window.wm.getToken(
      //       this.$store.getters.yd_product_id,
      //       function (token) {
      key(msg1)
        .then((res) => {
          _this.ft_key = res.data.ft_key
          _this.encrypted_data = encrypt(_this.password, _this.ft_key, iv)
          const mac = iv_data + _this.encrypted_data
          _this.mac_data = HmacSHA256(mac, _this.ft_key)
        })
        .then(() => {
          const passport = {
            body: _this.encrypted_data,
            iv: iv_data,
            mac: _this.mac_data,
          }
          const password = encodeBase64(JSON.stringify(passport))
          const data = {
            terminal: _this.terminal,
            verify_code: _this.verify_code,
            // body: _this.encrypted_data,
            // identity_no: _this.identity_no,
            // real_name: _this.real_name,
            // iv: iv_data,
            // mac: _this.mac_data,
            password: password,
            ft_key: _this.ft_key,
            token: _this.token,
          }
          const msg = Object.assign(
            JSON.parse(JSON.stringify(_this.configData)),
            data
          )
          terminalRegister(msg).then((res) => {
            setLocalStorage(
              'accountStorage',
              JSON.stringify(res.data.user_entity)
            )
            setToken('login', 'success', 7)
            _this.$router.push({ path: '/safetySettings' })
          })
        })
    },
    // 判断是否成年
    IdCard(UUserCard) {
      //获取年龄
      let myDate = new Date()
      let month = myDate.getMonth() + 1
      let day = myDate.getDate()
      let age = myDate.getFullYear() - UUserCard.substring(6, 10) - 1
      if (
        UUserCard.substring(10, 12) < month ||
        (UUserCard.substring(10, 12) == month &&
          UUserCard.substring(12, 14) <= day)
      ) {
        age++
      }
      return age
    },
    checkPhone() {
      const phone = this.terminal
      if (
        !/^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,5}$/.test(
          phone
        )
      ) {
        this.$toast(this.$t('toast.verifyphoneTips'))
        return false
      }
      return true
    },
    // 效验身份证号码
    verify_ID(id) {
      if (!IDreg.test(id)) {
        this.$toast(this.$t('toast.verifyIDTips'))
        return
      }
    },
  },
}
</script>
<style lang="scss" scope>
input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #dcdcdc !important;
}

input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #dcdcdc !important;
}

input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #dcdcdc !important;
}

input:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #dcdcdc !important;
}
@media (min-width: 1600px) {
  .register_box {
    width: 700px;
    // height: 790px;
    background: #fff;
    margin: 0 auto;
    padding: 40px 72px;
    box-sizing: border-box;
    margin-bottom: 58px;
    .pravic_tip {
      font-size: 20px;
      .link_to {
        text-decoration: underline;
        color: #ff7391;
      }
    }
    .ft_register_box {
      .register_item {
        width: 100%;
        height: 60px;
        border: 1px solid #cfcfcf;
        opacity: 1;
        border-radius: 6px;
        margin-top: 30px;
        padding: 10px 34px;
        box-sizing: border-box;
        input {
          width: 100%;
          height: 100%;
          outline: none;
          font-size: 20px;
          font-family: 'SimHei';
          font-weight: 400;
          // border: 1px solid #000;
        }
        .psw_code {
          width: 320px;
          height: 100%;
          font-size: 20px;
          outline: none;
        }
        .v_btn {
          width: 100px;
          height: 60px;
          img {
            width: 36px;
            margin-left: 60px;
            // margin: 0 auto;
          }
          cursor: pointer;
        }
      }
      .register_submit {
        margin-top: 50px;
        height: 60px;
        line-height: 60px;
        text-align: center;
        background: #ff7391;
        text-align: center;
        font-size: 26px;
        font-family: 'SimHei';
        font-weight: 400;
        color: #ffffff;
        border-radius: 6px;
        letter-spacing: 3px;
        cursor: pointer;
      }
      .verifiy_item {
        padding-right: 200px;
        position: relative;
        .verify_input {
          border-right: 1px solid #cfcfcf;
        }
        .verify_btn {
          width: 200px;
          outline: none;
          height: 60px;
          line-height: 60px;
          font-size: 20px;
          font-family: 'SimHei';
          font-weight: 400;
          text-align: center;
          color: #575757;
          cursor: pointer;
          position: absolute;
          right: 0;
          top: 0;
          background: none;
          border: none;
        }
      }
    }
  }
}
@media (min-width: 1200px) and (max-width:1599px) {
  .register_box {
    width: 700 * 0.85px;
    // height: 790 * 0.85px;
    background: #fff;
    margin: 0 auto;
    padding: 40 * 0.85px 72 * 0.85px;
    box-sizing: border-box;
    margin-bottom: 58 * 0.85px;
    .pravic_tip {
      font-size: 20 * 0.85px;
      .link_to {
        text-decoration: underline;
        color: #ff7391;
      }
    }
    .ft_register_box {
      .register_item {
        width: 100%;
        height: 60 * 0.85px;
        border: 1 * 0.85px solid #cfcfcf;
        opacity: 1;
        border-radius: 6 * 0.85px;
        margin-top: 30 * 0.85px;
        padding: 10 * 0.85px 34 * 0.85px;
        box-sizing: border-box;
        input {
          width: 100%;
          height: 100%;
          outline: none;
          font-size: 20 * 0.85px;
          font-family: 'SimHei';
          font-weight: 400;
          // border: 1*.85px solid #000;
        }
        .psw_code {
          width: 320 * 0.85px;
          height: 100%;
          font-size: 20 * 0.85px;
          outline: none;
        }
        .v_btn {
          width: 100 * 0.85px;
          height: 60 * 0.85px;
          img {
            width: 36 * 0.85px;
            margin-left: 60 * 0.85px;
          }
          cursor: pointer;
        }
      }
      .register_submit {
        margin-top: 50 * 0.85px;
        height: 60 * 0.85px;
        line-height: 60 * 0.85px;
        text-align: center;
        background: #ff7391;
        text-align: center;
        font-size: 26 * 0.85px;
        font-family: 'SimHei';
        font-weight: 400;
        color: #ffffff;
        border-radius: 6 * 0.85px;
        letter-spacing: 3 * 0.85px;
        cursor: pointer;
      }
      .verifiy_item {
        padding-right: 200 * 0.85px;
        position: relative;
        .verify_input {
          border-right: 1 * 0.85px solid #cfcfcf;
        }
        .verify_btn {
          width: 200 * 0.85px;
          outline: none;
          height: 60 * 0.85px;
          line-height: 60 * 0.85px;
          font-size: 20 * 0.85px;
          font-family: 'SimHei';
          font-weight: 400;
          text-align: center;
          color: #575757;
          cursor: pointer;
          position: absolute;
          right: 0;
          top: 0;
          background: none;
          border: none;
        }
      }
    }
  }
}
@media (min-width: 500px) and (max-width:1199px) {
  .register_box {
    width: 700 * 0.7px;
    // height: 790 * 0.7px;
    background: #fff;
    margin: 0 auto;
    padding: 40 * 0.7px 72 * 0.7px;
    box-sizing: border-box;
    margin-bottom: 58 * 0.7px;
    .pravic_tip {
      font-size: 20 * 0.7px;
      .link_to {
        text-decoration: underline;
        color: #ff7391;
      }
    }
    .ft_register_box {
      .register_item {
        width: 100%;
        height: 60 * 0.7px;
        border: 1 * 0.7px solid #cfcfcf;
        opacity: 1;
        border-radius: 6 * 0.7px;
        margin-top: 30 * 0.7px;
        padding: 10 * 0.7px 34 * 0.7px;
        box-sizing: border-box;
        input {
          width: 100%;
          height: 100%;
          outline: none;
          font-size: 20 * 0.7px;
          font-family: 'SimHei';
          font-weight: 400;
          // border: 1*.85px solid #000;
        }
        .psw_code {
          width: 320 * 0.7px;
          height: 100%;
          font-size: 20 * 0.7px;
          outline: none;
        }
        .v_btn {
          width: 100 * 0.7px;
          height: 60 * 0.7px;
          img {
            width: 36 * 0.7px;
            margin-left: 60 * 0.7px;
          }
          cursor: pointer;
        }
      }
      .register_submit {
        margin-top: 50 * 0.7px;
        height: 60 * 0.7px;
        line-height: 60 * 0.7px;
        text-align: center;
        background: #ff7391;
        text-align: center;
        font-size: 26 * 0.7px;
        font-family: 'SimHei';
        font-weight: 400;
        color: #ffffff;
        border-radius: 6 * 0.7px;
        letter-spacing: 3 * 0.7px;
        cursor: pointer;
      }
      .verifiy_item {
        padding-right: 200 * 0.7px;
        position: relative;
        .verify_input {
          border-right: 1 * 0.7px solid #cfcfcf;
        }
        .verify_btn {
          width: 200 * 0.7px;
          outline: none;
          height: 60 * 0.7px;
          line-height: 60 * 0.7px;
          font-size: 20 * 0.7px;
          font-family: 'SimHei';
          font-weight: 400;
          text-align: center;
          color: #575757;
          cursor: pointer;
          position: absolute;
          right: 0;
          top: 0;
          background: none;
          border: none;
        }
      }
    }
  }
}
@media screen and (max-width:499px) {
  .register_box {
    width: 400px;
    // height: 790 * 0.65px;
    background: #fff;
    margin: 0 auto;
    padding: 40 * 0.65px 72 * 0.65px;
    box-sizing: border-box;
    margin-bottom: 58 * 0.65px;
    .pravic_tip {
      font-size: 20 * 0.65px;
      .link_to {
        text-decoration: underline;
        color: #ff7391;
      }
    }
    .ft_register_box {
      .register_item {
        width: 100%;
        height: 60 * 0.65px;
        border: 1 * 0.65px solid #cfcfcf;
        opacity: 1;
        border-radius: 6 * 0.65px;
        margin-top: 30 * 0.65px;
        padding: 10 * 0.65px 34 * 0.65px;
        box-sizing: border-box;
        input {
          width: 100%;
          height: 100%;
          outline: none;
          font-size: 20 * 0.65px;
          font-family: 'SimHei';
          font-weight: 400;
          // border: 1*.85px solid #000;
        }
        .psw_code {
          width: 320 * 0.6px;
          height: 100%;
          font-size: 20 * 0.65px;
          outline: none;
        }
        .v_btn {
          width: 100 * 0.65px;
          height: 60 * 0.65px;
          img {
            width: 36 * 0.65px;
            margin-left: 60 * 0.65px;
          }
          cursor: pointer;
        }
      }
      .register_submit {
        width: 100%;
        margin-top: 50 * 0.65px;
        height: 60 * 0.65px;
        line-height: 60 * 0.65px;
        text-align: center;
        background: #ff7391;
        font-size: 26 * 0.65px;
        font-family: 'SimHei';
        font-weight: 400;
        color: #ffffff;
        border-radius: 6 * 0.65px;
        letter-spacing: 3 * 0.65px;
        cursor: pointer;
      }
      .verifiy_item {
        padding-right: 200 * 0.65px;
        position: relative;
        .verify_input {
          border-right: 1 * 0.65px solid #cfcfcf;
        }
        .verify_btn {
          width: 200 * 0.65px;
          outline: none;
          height: 60 * 0.65px;
          line-height: 60 * 0.65px;
          font-size: 20 * 0.65px;
          font-family: 'SimHei';
          font-weight: 400;
          text-align: center;
          color: #575757;
          cursor: pointer;
          position: absolute;
          right: 0;
          top: 0;
          background: none;
          border: none;
        }
      }
    }
  }
}
</style>
