<template>
  <div class="steam-btn" @click="steamLogin">
    <img src="../../assets/images/mycard/sits_small.png" alt="" />
  </div>
</template>

<script>
import { steamLogin } from "@/api/login";

export default {
  name: "steamLoginButton",
  data() {
    return {
      urlObj: {},
    };
  },
  props: {
    login_way_id: {},
  },
  created() {
    let url = window.location.search;
    if (url.indexOf("openid.ns") > 0) {
      const regObj = [
        { name: "%3A", val: ":" },
        { name: "%2F", val: "/" },
        { name: "%2C", val: "," },
      ];
      regObj.forEach((item) => {
        const reg = new RegExp(item.name, "g");
        url = url.replace(reg, item.val);
      });
      url = url.slice(1);
      url.split("&").forEach((item) => {
        let arr = item.split("=");
        this.urlObj[arr[0]] = arr[1];
      });
      this.urlObj["openid.mode"] = "check_authentication";
      // this.loginPost(this.urlObj);
      this.loginPost(url);
    }
  },
  methods: {
    steamLogin() {
      const params = {
        "openid.ns": "http://specs.openid.net/auth/2.0",
        "openid.mode": "checkid_setup",
        // "openid.realm": "http://localhost:3000",
        "openid.realm": "https://passport-web-pay.17atv.com/",
        "openid.claimed_id":
          "http://specs.openid.net/auth/2.0/identifier_select",
        "openid.identity": "http://specs.openid.net/auth/2.0/identifier_select",
        // "openid.return_to": "http://localhost:3000/#/accountlogin",
        "openid.return_to": "https://passport-web-pay.17atv.com/accountlogin",
      };
      let string = "";
      Object.keys(params).forEach((key) => {
        string = string + key + "=" + params[key] + "&";
      });
      string = string.slice(0, string.length - 1);

      window.location.href =
        "https://steamcommunity.com/openid/login?" + string;
    },
    loginPost(params) {
      // let string = "";
      // Object.keys(params).forEach((key) => {
      //   string = string + key + "=" + params[key] + "&";
      // });
      // let formData = `<form name="form1" id="form1" action="https://steamcommunity.com/openid/login" method="post">`;
      // Object.keys(params).forEach((key) => {
      //   formData += `<input type="hidden" name="${key}" value=${params[key]}>`;
      // });
      // formData += `<input type="submit" value="send"></form>`;
      // let div = document.createElement("div");
      // div.innerHTML = formData;
      // document.body.appendChild(div);
      // div.style.display = "none";
      // document.form1.submit();
      steamLogin({
        login_way_id: this.login_way_id,
        data: params,
        app_id: "3b0c348056a155bdbf415939a2c2de2c",
      })
        .then((res) => {
          this.$emit("SteamLoginResult", res);
        })
        .catch((err) => {
          this.$emit("SteamLoginErr", err);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.steam-btn {
  > img {
    height: 27px;
  }
}
</style>
